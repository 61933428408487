import './admin-view.css';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context';
import Button from '../../components/button/button';
import { Panel, Panels } from '../../components/panel/panel';
import PageTop from '../../components/page-top/page-top';
import { auth } from '../../firebase-config';
import { signInWithCustomToken, getIdTokenResult } from 'firebase/auth';
import List from '../../components/list/list';
import SubscriptionTransactions from '../subscription/subscription-transactions';
import { Timestamp } from 'firebase/firestore';
import SUBSCRIPTION_STATUS from '../../constants/payments/subscriptionStatus';

async function signInAsUser(customToken) {
  try {
    const userCredential = await signInWithCustomToken(auth, customToken);
    console.log('Successfully authenticated as user:', userCredential.user);
  } catch (error) {
    console.error('Error signing in with custom token:', error);
  }
}

export default function AdminView() {
  const userContext = useContext(UserContext);

  const [companies, setCompanies] = useState([]);
  const [transactions, setTransactions] = useState([]);

  // Check if user is superadmin, if not, redirect to home
  useEffect(() => {
    const checkSuperadmin = async () => {
      const idTokenResult = await getIdTokenResult(auth.currentUser);
      if (!idTokenResult.claims.superadmin) {
        window.location.href = '/';
      }
    };

    if (userContext.uid) {
      checkSuperadmin();
    }
  }, [userContext.uid]);

  const emulateUser = async (userUUIDToEmulate) => {
    try {
      // Store the super admin UUID in local storage
      localStorage.setItem('superAdminUUID', auth.currentUser.uid);

      // API call to emulate another user
      const response = await fetch('/api/admin/emulateuser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-user-uuid': userContext.uid,
        },
        body: JSON.stringify({ userUUID: userUUIDToEmulate }),
      });

      const data = await response.json();

      if (response.ok && data.customToken) {
        signInAsUser(data.customToken);
      } else {
        console.log('Error:', data);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleFetchCompanies = async () => {
    const response = await fetch('/api/admin/companies', {
      headers: {
        'Content-Type': 'application/json',
        'x-user-uuid': userContext.uid,
      },
    });
    if (!response.ok) {
      alert('Error fetching companies');
      console.error('Error fetching companies:', response);
      return;
    }

    const data = await response.json();

    if (data) {
      const newCompanies = [];
      const newTransactions = [];

      data?.companies?.map((company) => {
        newCompanies.push({
          link: `/admin/virksomhed/${company.id}`,
          id: company.id,
          fields: [
            {
              flex: 20,
              label: 'Virksomhed',
              value: (
                <div className="admin-users-list-row-group">
                  <div>
                    <span className="admin-users-list-row-name">
                      {company?.data?.name}
                    </span>
                    <span className="admin-users-list-row-small">
                      CVR: {company?.data?.vat}
                    </span>
                    <span className="admin-users-list-row-small">
                      ID: {company?.id}
                    </span>
                  </div>
                </div>
              ),
              sortBy: company?.data?.name.toLowerCase() || '',
              searchBy: company?.data?.name.toLowerCase() || '',
            },
            {
              flex: 20,
              label: 'Abonnement',
              value: (
                <div className="admin-users-list-row-group">
                  <div>
                    <span className="admin-users-list-row-name">
                      {company?.subscriptionData?.name}{' '}
                      {company?.subscriptionData?.trial ? '(Prøveperiode)' : ''}
                    </span>
                    {company?.onPaySubscriptionData?.onPayData?.data
                      ?.order_id && (
                      <span className="admin-users-list-row-small">
                        ID:{' '}
                        {
                          company?.onPaySubscriptionData?.onPayData?.data
                            ?.order_id
                        }
                      </span>
                    )}
                  </div>
                </div>
              ),
              sortBy: company?.subscriptionData?.name.toLowerCase() || '',
              searchBy: `${
                company?.subscriptionData?.name.toLowerCase() || ''
              } ${
                company?.onPaySubscriptionData?.onPayData?.data?.order_id || ''
              }`,
            },
            {
              flex: 15,
              label: 'Status',
              value: SUBSCRIPTION_STATUS.find(
                (status) => status.value === company?.subscriptionData?.status
              )?.label,
            },
            {
              label: 'Oprettet d.',
              value: new Date(company?.createTime)?.toLocaleDateString(
                'da-DK',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }
              ),
              sortBy: company?.createTime,
              searchBy: company?.createTime,
            },
            {
              label: 'Medarbejdere',
              value: (
                <div className="admin-users-list-row-group">
                  <div>
                    <span className="admin-users-list-row-name">
                      {company?.users?.length}
                    </span>
                    <span className="admin-users-list-row-small">
                      M. tidsreg.:{' '}
                      {
                        company?.users?.filter((user) => user.hasMobileAccess)
                          .length
                      }
                    </span>
                  </div>
                </div>
              ),
              sortBy: company.users.length,
              searchBy: company.users.length,
            },
            {
              label: 'Udstyr',
              flex: 10,
              value: company.hasEquipment ? 'Ja' : 'Nej',
              sortBy: company.hasEquipment,
              searchBy: company.hasEquipment,
            },
            {
              label: 'Log på',
              value: (
                <Button
                  className="small"
                  style={{ marginLeft: 'auto' }}
                  buttonStyle="text-link"
                  onClick={() => {
                    emulateUser(company?.createdBy?.id);
                  }}
                >
                  Log på
                </Button>
              ),
            },
          ],
          filterData: {
            ID: company.id,
            Virksomhed: company?.data?.name.toLowerCase(),
            Abonnement: `${company?.subscriptionData?.name?.toLowerCase()} ${
              company?.onPaySubscriptionData?.onPayData?.data?.order_id
            }`,
            Status: company?.subscriptionData?.status,
            'Oprettet d.': company.createTime,
            Medarbejdere: company.users.length,
            Udstyr: company.hasEquipment,
          },
        });

        newTransactions.push(
          company?.transactions?.map((transaction) => ({
            id: transaction.id,
            data: {
              ...transaction.data,
              date_created: new Timestamp(
                transaction.data.date_created._seconds,
                transaction.data.date_created._nanoseconds
              ),
            },
          }))
        );
      });

      setCompanies(newCompanies);
      setTransactions(newTransactions.flat());
    }
  };

  useEffect(() => {
    handleFetchCompanies();
  }, []);

  return (
    <div className="main-layout">
      <PageTop title="Admin" />
      <Panels>
        {companies && companies.length > 0 && (
          <Panel noPadding header={<h4>Virksomheder</h4>} headerTop>
            <List
              showSearch
              head={[
                { label: 'Virksomhed', flex: 20, sortable: true },
                { label: 'Abonnement', flex: 20, sortable: true },
                { label: 'Status', flex: 15, sortable: true },
                { label: 'Oprettet d.', sortable: true },
                { label: 'Medarbejdere', sortable: true },
                { label: 'Udstyr', flex: 10, sortable: true },
                { label: 'Log på', align: 'right' },
              ]}
              items={companies}
            />
          </Panel>
        )}
        <SubscriptionTransactions
          transactions={transactions?.sort((a, b) => {
            return b.data.date_created - a.data.date_created;
          })}
        />
      </Panels>
    </div>
  );
}
