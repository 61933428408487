import { useState, useContext } from 'react';
import './employees-view.css';
import {
  EmployeesContext,
  SubscriptionContext,
  UserInfoContext,
} from '../../context';
import { Panels, Panel } from '../../components/panel/panel';
import List from '../../components/list/list';
import Avatar from '../../components/avatar/avatar';
import PageTop from '../../components/page-top/page-top';
import Button from '../../components/button/button';
import Icon from '../../components/icon/icon';
import EMPLOYMENT_TYPE from '../../constants/fields/employmentType';
import EMPLOYMENT_STATUS from '../../constants/fields/employmentStatus';
import EmployeeCreateModal from './employee-create-modal';
import EmptyState from '../../components/empty-state/empty-state';
import EmptyStateImage from './emptystate.webp';
import SubscriptionPlansModal from '../subscription/subscription-plans-modal';

export default function EmployeesView() {
  const userInfo = useContext(UserInfoContext);
  const employees = useContext(EmployeesContext);
  const { subscription } = useContext(SubscriptionContext);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isPlansModalOpen, setIsPlansModalOpen] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  // If there are no employees, return null to avoid rendering the component
  if (employees.length === 0) return null;

  const subscriptionName = subscription?.name || '';
  const limit =
    subscriptionName === 'premium'
      ? 102
      : subscriptionName === 'premiumplus'
      ? 1000000
      : 11;

  // Sort first by date_created (in case we need to limit the number of employees), then by firstName
  const sortedEmployees = employees.sort((a, b) => {
    if (a.data.date_created > b.data.date_created) return 1;
    if (a.data.date_created < b.data.date_created) return -1;
    if (a.data.firstName < b.data.firstName) return -1;
    if (a.data.firstName > b.data.firstName) return 1;
    return 0;
  });

  // Limit the number of employees to 11 for freemium users (11 because we want to display 10 employees plus one more to tease the user to upgrade)
  const limitedEmployees = sortedEmployees.slice(0, limit);

  return (
    <div className="main-layout">
      <PageTop
        title="Medarbejdere"
        description="Få hurtigt overblik over alle kollegaerne og tilføj nemt og sikkert nye."
        actions={
          <Button onClick={() => setIsCreateModalOpen(true)}>
            <Icon className="light" name="add_circle" />
            Opret medarbejder
          </Button>
        }
      />

      <Panels>
        <Panel noPadding>
          <List
            className="employees-list"
            head={[
              { label: 'Navn', sortable: true },
              { label: 'Ansættelsestype', sortable: true },
              { label: 'Ansættelsesdato', sortable: true },
              { label: 'Status', sortable: true },
            ]}
            filters={[
              {
                label: 'Status',
                options: EMPLOYMENT_STATUS,
              },
              {
                label: 'Ansættelsestype',
                options: EMPLOYMENT_TYPE,
              },
              {
                label: 'Vis inaktive',
                type: 'checkbox',
                onChange: (checked) => setShowInactive(checked),
              },
            ]}
            showSearch
            items={limitedEmployees
              .map((employee, index) => ({
                // If the looped employee is the last one and the user has more than 10 or 100 employees based on the subscription, disable the link
                link:
                  sortedEmployees.length > limit && index === limit - 1
                    ? null
                    : `/medarbejdere/${employee.id}`,
                id: employee.id,
                fields: [
                  {
                    label: 'Navn',
                    value: (
                      <div
                        className={`employees-list-row-profile
                      ${
                        employee.data.employmentStatus === 'inactive'
                          ? 'employees-list-row-profile--inactive'
                          : ''
                      }
                      `}
                      >
                        <Avatar
                          firstName={employee.data.firstName}
                          lastName={employee.data.lastName}
                          profilePhotoUrl={employee.data.profilePhotoUrl}
                        />
                        <div className="employees-list-row-name-wrapper">
                          <span className="employees-list-row-name">
                            {employee.data.firstName} {employee.data.lastName}
                          </span>
                          <span className="employees-list-row-job">
                            {employee.data.jobPosition}
                          </span>
                        </div>
                      </div>
                    ),
                    sortBy: `${employee.data.firstName} ${employee.data.lastName}`,
                    searchBy: `${employee.data.firstName} ${employee.data.lastName}`,
                  },
                  {
                    label: 'Ansættelsestype',
                    value:
                      EMPLOYMENT_TYPE.find(
                        (status) =>
                          status.value === employee.data.employmentType
                      )?.label || 'Ikke angivet',
                    sortBy:
                      EMPLOYMENT_TYPE.find(
                        (status) =>
                          status.value === employee.data.employmentType
                      )?.label || 'Ikke angivet',
                  },
                  {
                    label: 'Ansættelsesdato',
                    value:
                      (employee.data.startDate &&
                        employee.data.startDate
                          .toDate()
                          .toLocaleDateString('da-DK')) ||
                      'Ikke angivet',
                    sortBy: employee.data.startDate
                      ? new Date(employee.data.startDate.toDate()).getTime()
                      : 0,
                  },
                  {
                    label: 'Status',
                    value:
                      EMPLOYMENT_STATUS.find(
                        (status) =>
                          status.value === employee.data.employmentStatus
                      )?.label || 'Ikke angivet',
                  },
                ],
                filterData: {
                  Navn: `${employee.data.firstName} ${employee.data.lastName}`,
                  Ansættelsestype: employee.data.employmentType,
                  Ansættelsesdato: employee.data.startDate
                    ? new Date(employee.data.startDate.toDate()).getTime()
                    : 0,
                  Status: employee.data.employmentStatus,
                  'Vis inaktive':
                    employee?.data?.employmentStatus === 'inactive'
                      ? true
                      : false,
                },
              }))
              .filter(
                (item) => showInactive || !item.filterData['Vis inaktive']
              )}
          />
          {
            // If the user has more than 10 or 100 employees based on the subscription, show a CTA to upgrade
            sortedEmployees.length > limit && (
              <div className="employees-limited-cta">
                <Icon name="group" />
                <h4>
                  Opgradér abonnement for at have mere end 10 medarbejdere
                  oprettet
                </h4>
                <p>
                  Du har adgang til de første 10 medarbejdere.
                  <br />
                  Opgradér abonnement for at få adgang til alle dine{' '}
                  {sortedEmployees.length} medarbejdere.
                </p>
                <Button onClick={() => setIsPlansModalOpen(true)}>
                  Vis prisoversigt
                </Button>
              </div>
            )
          }
        </Panel>
      </Panels>
      {employees.length < 2 && (
        <EmptyState
          title="Få hurtigt overblik over alle kollegaerne og tilføj nemt og sikkert nye."
          description="Opbevar og administrer alt medarbejderdata ét sted. Her kan du nemt tilføje nye medarbejdere samt redigere og søge efter oplysninger på en struktureret og sikker måde, så du altid har overblik og hurtigt kan finde de oplysninger du skal bruge."
          image={EmptyStateImage}
        >
          <Button onClick={() => setIsCreateModalOpen(true)}>
            <Icon className="light" name="add_circle" />
            Opret din første medarbejder
          </Button>
        </EmptyState>
      )}

      <EmployeeCreateModal
        isModalOpen={isCreateModalOpen}
        setIsModalOpen={setIsCreateModalOpen}
        setIsPlansModalOpen={setIsPlansModalOpen}
      />
      <SubscriptionPlansModal
        isModalOpen={isPlansModalOpen}
        setIsModalOpen={setIsPlansModalOpen}
        companyId={userInfo?.companyId}
        userId={userInfo.id}
      />
    </div>
  );
}
